import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import FormFields from "../../static/forms/career.json"
import { PageLinks } from "../common/site/page-static-links"
// import CareerContactModule from "../components/CareerContactModule/CareerContactModule"
import SocialShare from "../components/SocialShare/SocialShare"
import DefaultForm from "../components/forms/default-form-module"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { removeDataAttributes } from "../components/Common/utils"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"


const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const CareerDetailIntro = loadable(() =>
  import("../components/CareerDetailIntro/CareerDetailIntro")
)
const CareerContactModule = loadable(() =>
  import("../components/CareerContactModule/CareerContactModule")
)
const CareerDetailDesc = loadable(() =>
  import("../components/CareerDetailDesc/CareerDetailDesc")
)
const CareerDetailSidebar = loadable(() =>
  import("../components/CareerDetailSidebar/CareerDetailSidebar")
)
// const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const CareerDetail = ({ data, pageContext }, props) => {
  const [careerData, setCareerData] = useState(data?.strapiCareer)
  const PageData = careerData
  // const [loading,setLoading] = useState(true)
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string


  // Sticky scroll
  const [scroll, setScroll] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 80)
    })
  }, [])
  // Sticky scroll

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const { isLargeScreen } = useDeviceMedia()
  // console.log(PageData,"67890")
  const teamData = PageData?.sidebar?.team

  const pageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (pageData?.add_page_modules?.length > 0) {
    const results = pageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        sidebar:{
          populate:{
            team:{
              populate:{
                image:"*"
              }
            },
            cta:{
              populate:{
                link:"*"
              }
            }
          }
        }
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );


  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/careers/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.sidebar = {}
          myPrevData.id = urlParams.get('strapi_id')
        }
        if(previewData){
          myPrevData.location = previewData?.location
          myPrevData.job_type = previewData?.job_type
          myPrevData.sidebar = previewData?.sidebar
          myPrevData.job_details = previewData?.job_details
          myPrevData.title = previewData?.title

          // marketData.read_time = previewData?.read_time
          setCareerData(PageData => ({ ...PageData, myPrevData }));
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])


    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }

    if(enablePreview){
      return null
    }

  return (
    <Layout
      popUpData={popUpData}
      nonpropertyDetails
      dark={true}
      footertag={"career-contact"}
      footerClass="property-footer"
    >
      <div className="layout-padding-top"></div>
      <BreadcrumbModule
        mainparentname={PageLinks.about_label}
        mainparent={PageLinks.about}
        parentname={PageLinks.career_label}
        parent={PageLinks.career}
        pagename={PageData.title}
        type={"details-page-career"}
      />

      <div className="career-detail-wrapper">
        <Container>
          <Row>
            <Col xl={7}>
              <CareerDetailIntro {...PageData} />

              <CareerDetailDesc isPreviewEnabled={isPreviewEnabled} {...PageData} />
            </Col>

            <Col xl={1}></Col>


            {!isLargeScreen && (
              <Col xl={4}>
                <div
                  className={`career-sidebar position-sticky ${
                    scroll ? "scrolled" : ""
                  }`}
                >
                  <CareerContactModule isPreviewEnabled={isPreviewEnabled} propertyInfo={PageData.sidebar} pageData={PageData} />
                </div>
              </Col>
            )}

            {isLargeScreen&&
              <CareerContactModule isPreviewEnabled={isPreviewEnabled} propertyInfo={PageData.sidebar} pageData={PageData}/>
            }
          </Row>
        </Container>

        {isLargeScreen && teamData && (
          <div className="career-footer-ctas">
            <a className="button button-filled-green" onClick={handleModal}>
              <span>Apply</span>
            </a>
            <a className="button button-outline-grey">
              <SocialShare iconClass="icon icon-share" shareText="Share" />
            </a>
          </div>
        )}
      </div>

      {
        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form"
        >
          <Modal.Body>
            <div className="popup-form-wrapper">
              <div className="close-modal" onClick={() => handleClose()}>
                <i className="icon black-cancel-icon"></i>
              </div>
              <div>
                <DefaultForm
                  fields={FormFields}
                  sourceUrl={pageurl}
                  formTitle="Careers Submission"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

      {/* <div className="d-xl-none">
                <FooterContactMobile
                    tag="career-detail"
                />
            </div> */}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiCareer
  const metaDescription = `Join our dynamic team as a ${PageData.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`

  let pageUrl = typeof window !== "undefined" ? window.location.href : ""
 //breadscrumb schema
 let dynamicmenu1 ="Home"
 let dynamicmenu1Url = `${process.env.GATSBY_SITE_URL}/`
 let dynamicmenu2 = "About us"
 let dynamicmenu2Url=`${process.env.GATSBY_SITE_URL}/about-us/`
 let dynamicmenu3 = "Careers"
 let dynamicmenu3Url =`${process.env.GATSBY_SITE_URL}/about-us/careers/`
 let dynamicmenu4 = PageData?.title
 let dynamicmenu4Url = `${process.env.GATSBY_SITE_URL}/about-us/careers/${PageData?.slug}`

 let breadcrumbJson = {
   "@context": "https://schema.org",	
 "@type": "BreadcrumbList",
 "@id": "BreadcrumbList",
 itemListElement: [	
   {	
     type: "ListItem",	
     position: 1,	
     name: dynamicmenu1,	
     item: dynamicmenu1Url	
   },	
 ]	
 }

 if(dynamicmenu2){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 2,	
       name: dynamicmenu2,	
       item: dynamicmenu2Url	
     }
   )
 }

 if(dynamicmenu3){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 3,	
       name: dynamicmenu3,	
       item: dynamicmenu3Url	
     }
   )
 } 

 if(dynamicmenu4 && dynamicmenu4Url){
   breadcrumbJson.itemListElement.push(
     {
       type: "ListItem",	
       position: 4,	
       name: dynamicmenu4,	
       item: dynamicmenu4Url	
     }
   )
 } 
 //-----------------------

 let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData.title

  const [preMetaTitle, setPreMetaTitle] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
            setPreMetaTitle(metaTitle)
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${metaTitle} | haus & haus`;
    }
  }, [metaTitle]);
  

  const careerSchema = {
    "@context":"http://schema.org/",
    "@type": "JobPosting",
    "@id": "JobPosting",
    "title" : PageData?.title,
    "description" : PageData?.job_details?.data?.job_details?.replace(/(<([^>]+)>)/ig, ''),
    "datePosted": PageData?.createdAt,
    "hiringOrganization": {
    "@type": "Organization",
    "sameAs":
    "https://www.hausandhaus.com/",
    "name": "haus & haus"
    },
    "employmentType" : [PageData?.job_type],
    "jobLocation": {
      "@type": "Place",
      "address": {
      "streetAddress": "Office 01, Building No 7, Gold & Diamond Park- Sheikh Zayed Rd ",
      "addressLocality": "Dubai",
      "addressRegion": "Dubai",
      "addressCountry": "United Arab Emirates"
      }
    }
 
 }
  


  return(
    <>
      <Seo title={metaTitle} description={metaDescription}
        imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJson) }}
      />
       <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(careerSchema)}}
      />
    </>
  ) 
}
export default CareerDetail

export const query = graphql`
  query ($page_id: Int) {
    strapiCareer(strapi_id: { eq: $page_id }) {
      ...CareerFragment
      createdAt
      sidebar {
        team {
          image {
            url
          }
          face_image{
            url
          }
          designation
          name
          slug
          negotiator_id
          email
          phone
          imagetransforms {
            image_Transforms
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              format
              transform
            }
          }
          Languages_Spoken {
            strapi_json_value
          }
          strapi_id
          whatsapp
          language_speaks
        }
        title
        content {
          data {
            content
          }
        }
        cta {
          title
          custom_link
          link_type
          link {
            slug
          }
        }
      }
      job_details {
        data {
          job_details
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "careers_landing_page" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
